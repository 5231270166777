<template>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
          :disabled="user.isManager"
        >
          Send Message
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline primary lighten-2 white--text">
          Send Message
        </v-card-title>

        <v-card-text
         class="mt-5">
          <v-textarea
            v-model="message"
            label="Message"
            outlined
            dense />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="submit"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import userMixin from '@/components/mixins/userMixin';
import { sendMessage } from '@/shared/apis/messageApi';

export default {
  name: 'send-message-modal',
  mixins: [userMixin],
  props: {
    phoneNumber: String,
    ticketId: Number,
  },
  data() {
    return {
      dialog: false,
      message: '',
    };
  },
  computed: {
    userId() {
      return this.$store.state.user.user.id;
    },
  },
  methods: {
    async submit() {
      try {
        const result = await sendMessage(
          {
            to: this.phoneNumber,
            body: this.message,
            ticketId: this.ticketId,
          },
        );
        if (result) {
          this.dialog = false;
          this.$emit('reload-ticket');
        } else console.log('message did not send successfully.');
      } catch {
        console.log('error occurred while sending messaage.');
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
